<template>
  <v-toolbar flat class="mb-6">
    <!-- Contagem da paginação -->
    <pagination-count :pagination="pagination" />

    <!--  -->
    <v-spacer></v-spacer>

    <!-- Paginação -->
    <v-pagination
      :total-visible="$vuetify.breakpoint.xsOnly ? 0 : 7"
      v-model="page"
      class="my-4 elevation-0"
      :length="pagination.lastPage"
    ></v-pagination>
  </v-toolbar>
</template>

<script>
import PaginationCount from "./Count.vue";
export default {
  components: {
    PaginationCount
  },

  props: {
    pagination: Object,
    value: Number
  },
  data() {
    return {
      page: 1
    };
  },
  watch: {
    page() {
      // console.log("trocou a page", this.page);
      this.$emit("input", this.page);
    },
    value() {
      this.page = this.value;
      // console.log("alterou page pra ser igual a value", this.value);
      // console.log(this.$vuetify.breakpoint);
      // this.$emit("input", this.value);
    }
  },
  mounted() {
    this.page = this.value;
  }
};
</script>

<style>
.theme--dark.v-pagination .v-pagination__item--active {
  color: #1e1e1e !important;
}
</style>