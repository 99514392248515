<template>
  <v-card
    :color="color"
    :min-height="small ? 80 : 250"
    :minWidth="minWidth"
    :width="width"
    class="pa-1 d-flex flex-column justify-space-between"
    :to="to"
    :elevation="elevation"
    v-bind="$attrs"
  >
    <v-sheet :color="sheetColor" :min-height="small ? 40 : 230">
      <v-card-text class="fill-height justify-center d-flex align-start">
        <slot></slot>
      </v-card-text>
    </v-sheet>
    <div>
      <v-divider></v-divider>
      <v-card-actions
        class="body-2"
        style="height: 30px; align-items: center"
        >{{ title }}</v-card-actions
      >
    </div>
  </v-card>
</template>

<script>
export default {
  name: "graphic-info",
  components: {},
  props: {
    // Título que aparcerá no rodapé do v-card
    title: String,
    //
    small: Boolean,
    // Atributos para o v-card do gráfico
    // Altura da elevação
    elevation: { type: [String, Number], default: 1 },
    // Cor do v-card
    color: { type: String, default: "#FBFBFB" },
    // Cor interna do gráfico
    sheetColor: { type: String, default: "rgba(0,0,0,.05)" },
    //
    to: { type: String, default: null },
    //
    width: { type: [String, Number], default: "100%" },
    //
    minWidth: { type: [String, Number], default: 350 },
    //
    minHeight: { type: [String, Number], default: 350 }
  }
};
</script>

<style scoped>
.echarts div {
  width: 200px;
  height: 100%;
}
.echarts {
  width: 100%;
  height: 100%;
}
</style>