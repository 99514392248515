<template>
  <v-card outlined class="overflow-hidden">
    <v-row>
      <v-col>
        <v-list class="py-0 bordered">
          <!--  -->
          <ListItem
            v-for="item in list"
            :key="`item-${item.id}`"
            :item="item"
            :hideActions="hideActions"
            @click:item="onItemClick(item)"
            @click:cancel="onCancelClick(item.id)"
            @click:edit="onEditClick(item)"
            @click:reset="onResetClick(item.id)"
            @click:billet="onBilletClick(item.charge_public_id)"
            @click:billet_generate="onBilletGenerateClick(item)"
          />
        </v-list>
      </v-col>
    </v-row>

    <DialogCancel ref="dialog_cancel" @save="updateInList" />
    <DialogReset ref="dialog_reset" @save="updateInList" />
    <DialogEdit ref="dialog_edit" @save="updateInList" />
    <DialogEditInitial ref="dialog_edit_initial" @save="updateInList" />

    <DialogView
      ref="dialog_view"
      @click:next="onNextClick"
      @click:previous="onPreviousClick"
    />
  </v-card>
</template>

<script>
import ListItem from "./ListItem";
import FilterBar from "./FilterBar";
import DialogView from "./DialogView";
import DialogEdit from "./DialogEdit";
import DialogReset from "./DialogReset";
import DialogCancel from "./DialogCancel";
import DialogEditInitial from "./DialogEditInitial";

export default {
  components: {
    ListItem,
    FilterBar,
    DialogEdit,
    DialogView,
    DialogReset,
    DialogCancel,
    DialogEditInitial
  },

  props: {
    items: Array,
    hideActions: Boolean
  },

  data() {
    return {
      list: []
    };
  },

  watch: {
    items: {
      deep: true,
      handler(val) {
        this.list = val;
      }
    }
  },

  //
  methods: {
    /**
     * Altera os dados na lista
     */
    updateInList(payload) {
      try {
        // console.log("updateInList", payload);
        const idx = this.list.findIndex(item => item.id === payload.id);
        this.$set(this.list, idx, payload);
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * Ao clicar no botão de cancelar coleta
     */
    onCancelClick(id) {
      // console.log("cancel", id);
      this.$refs.dialog_cancel.openToCancel(id);
    },

    /**
     * Ao clicar no botão de cancelar coleta
     */
    onEditClick(item) {
      if (item.is_initial) {
        this.$refs.dialog_edit_initial.openToEdit(item);
      } else {
        this.$refs.dialog_edit.openToEdit(item);
      }
    },

    /**
     * Ao clicar no botão de cancelar coleta
     */
    onResetClick(id) {
      // console.log("cancel", id);
      this.$refs.dialog_reset.openToReset(id);
      // this.$message.show("Não implementado");
    },

    /**
     * Ao clicar em uma linha
     */
    onItemClick(item) {
      this.$refs.dialog_view.openToView({ ...item });
    },

    onBilletClick(charge_public_id) {
      window.open(
        this.$api.url(
          "/financials/charges/" + charge_public_id + "/billet_web"
        ),
        "_blank"
      );
    },

    async onBilletGenerateClick(payload) {
      try {
        this.$loading.show();

        //
        const data = await this.$store.dispatch(
          "financials/billetGenerate",
          payload.charge_public_id
        );

        console.log("dados recebidos dentro do component", data);

        this.$store.dispatch("collections/updateItemInList", {
          ...payload,
          charge_id: data.charge.id,
          charge_public_id: data.charge.public_id,
          charge_has_billet: 1
        });

        this.$loading.hide();
      } catch (error) {
        this.$loading.hide();
        this.$message.serverError(error);
      }
    },

    /**
     * Ao clicar em avançar
     */
    onNextClick(id) {
      const idx = this.items.findIndex(item => item.id === id);

      if (idx === this.items.length - 1) {
        this.$message.show("Já está no final da lista");
      } else {
        this.$refs.dialog_view.close();
        setTimeout(() => {
          this.onItemClick(this.items[idx + 1]);
        }, 300);
      }
    },

    /**
     * Ao clicar em voltar
     */
    onPreviousClick(id) {
      const idx = this.items.findIndex(item => item.id === id);

      if (idx === 0) {
        this.$message.show("Já está no primeiro item da lista");
      } else {
        this.$refs.dialog_view.close();
        setTimeout(() => {
          this.onItemClick(this.items[idx - 1]);
        }, 300);
      }
    }
  },
  mounted() {
    this.list = this.items;
  }
};
</script>
