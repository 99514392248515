<template>
  <KDialog
    show-close
    :visible="visible"
    :loading="loading"
    :title="`Editar Coleta Inicial #${this.form.id}`"
    @click:close="visible = false"
    @click:edit="saveEdit"
    :actions="[{ eventName: 'click:edit', label: 'Editar Coleta' }]"
  >
    <KForm ref="form">
      <!-- Não está finalizado ainda -->
      <v-row class="ma-0">
        <v-col cols="12">
          <KAlert warning title="Atenção">
            Uma coleta inicial só pode ser alterada se não existirem outras
            coletas posteriores a ela.
          </KAlert>
        </v-col>
        <v-col cols="12" md="6">
          <KLabel label="Unidade de Consumo">
            {{ form.condominium_name }} - {{ form.unit_identification }}
          </KLabel>
        </v-col>
        <v-col cols="12" md="6">
          <KLabel label="Cliente">
            {{ form.customer_name }}
          </KLabel>
        </v-col>

        <v-col cols="12">
          <KInputMoney
            dense
            label="Valor coletado"
            outlined
            :precision="3"
            suffix="m³"
            :rules="[$validation.isRequired]"
            v-model="form.collected_value"
            :error-messages="errors.collected_value"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Motivo da alteração"
            counter="250"
            outlined
            rows="2"
            v-model="form.comments"
            :error-messages="errors.comments"
            :rules="[
              $validation.isRequired,
              $validation.hasMaxLength(form.comments, '250'),
            ]"
          >
          </v-textarea>
        </v-col>
      </v-row>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KLabel from "@/components/KLabel";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
import KInputMoney from "@/components/KInput/Money";

export default {
  components: {
    KForm,
    KLabel,
    KAlert,
    KDialog,
    KInputMoney
  },

  data() {
    return {
      loading: false,
      visible: false,
      form: {},
      singleCollect: {},
      errors: {}
    };
  },

  methods: {
    async saveEdit() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          const { data } = await this.$api.patch(
            `/collections/${this.form.id}/update_initial`,
            this.form
          );

          this.visible = false;
          this.loading = false;
          this.form = {};

          this.$emit("save", data.data);
        }
      } catch (error) {
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    openToEdit(payload) {
      this.form = { ...payload, comments: null };

      this.visible = true;
    }
  }
};
</script>

<style>
</style>
