<template>
  <v-card
    outlined
    height="100%"
    class="align-center justify-center mx-2 flex-grow-1 rounded-lg"
    :to="to"
    :href="href"
    :target="target"
    :hover="hasAction"
    v-on="actions"
  >
    <v-card-text
      style="min-height: 72px"
      class="d-flex align-center justify-center text-h5"
      :class="{
        'primary--text': value,
        'grey--text text--lighten-2': !value,
      }"
    >
      <v-icon
        size="36"
        :color="calculatedColor"
        :class="{ 'mr-5': hasValue }"
        >{{ icon }}</v-icon
      >
      <template v-if="type === 'currency'">{{
        (value || "0") | currency
      }}</template>
      <template v-else>{{ value }}</template>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions class="caption overflow-hidden">
      <p class="ma-0 d-block text-truncate">
        {{ label }}
      </p>
      <template v-if="hasAction && !target">
        <v-spacer></v-spacer>
        <v-icon size="16">mdi-chevron-right</v-icon>
      </template>
      <template v-if="hasAction && target">
        <v-spacer></v-spacer>
        <v-icon size="16">mdi-open-in-new</v-icon>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import { empty } from "klawtil";
export default {
  props: {
    value: [Number, String],
    icon: String,
    label: String,
    color: String,
    type: String,
    to: String,
    href: String,
    target: String,
    click: Function,
    // click: { type: [Function, Boolean], default: false }
  },

  computed: {
    actions() {
      if (this.click) {
        return { click: this.click };
      }
    },

    hasAction() {
      return !!this.to || !!this.click || !!this.href;
    },

    hasValue() {
      return !empty(this.value);
    },

    calculatedColor() {
      if (this.value || this.hasAction) {
        if (this.color) return this.color;
        else return "primary";
      } else {
        return "grey lighten-2";
      }
    },
  },
};
</script>

<style>
</style>
