<template>
  <KDialog
    max-width="1200"
    :title="title"
    :loading="loading"
    :visible="visible"
    @click:close="close"
  >
    <!--  -->
    <v-row class="my-1">
      <!--  -->
      <chip-code v-if="data.code" medium :code="data.code" />

      <!--  -->
      <chip-method v-if="data.method" medium :method="data.method" />

      <!-- Data/Hora -->
      <chip-date medium :date="data.created_at" />

      <!-- Módulo de Referência -->
      <KChip medium v-if="data.reference_module">
        Ref:
        {{ data.reference_module }}
        {{ data.reference_id && ` : ${data.reference_id}` }}
      </KChip>

      <!-- Usuário -->
      <KChip medium pink v-if="data.user">Usuário: {{ data.user.name }}</KChip>

      <!-- URL -->
      <KChip medium v-if="data.url">Url: {{ data.url }}</KChip>

      <!-- Status -->
      <KChip
        medium
        v-if="data.status"
        title="HTTP Status"
        icon="mdi-exclamation"
        >{{ data.status }}</KChip
      >

      <!-- IP -->
      <KChip medium v-if="data.ip" title="IP" icon="mdi-web">{{
        data.ip
      }}</KChip>

      <!--  -->
      <KChip
        medium
        v-if="data.error_type"
        title="Tipo do Erro"
        icon="mdi-account"
        >{{ data.error_type }}</KChip
      >

      <!--  -->
      <KChip medium v-if="data.os_name" title="Sistema Operacional"
        >{{ data.os_name }} {{ data.os_version }}</KChip
      >

      <!--  -->
      <KChip medium v-if="data.browser_name" title="Browser"
        >{{ data.browser_name }} {{ data.browser_version }}</KChip
      >

      <!-- Aparelho -->
      <KChip medium v-if="data.device_vendor" title="Aparelho"
        >{{ data.device_vendor }} {{ data.device_name }}</KChip
      >

      <!-- Aparelho -->
      <KChip medium v-if="data.device_type" title="Tipo">{{
        data.device_type
      }}</KChip>
    </v-row>

    <!--  -->
    <v-row>
      <!--  -->
      <v-col cols="12" sm="12">
        <log-group
          :content="data.message"
          title="Message"
          description="Mensagem do log"
        ></log-group>
      </v-col>

      <!--  -->
      <v-col cols="12" sm="12">
        <log-group
          :content="data.headers"
          title="Headers"
          type="object"
          description="Cabeçalhos HTTP"
        />
      </v-col>

      <!--  -->
      <v-col cols="12" sm="12">
        <log-group
          :content="data.params"
          description="Parâmetros da URL"
          title="Params"
          type="object"
          hide-if-empty
        />
      </v-col>

      <!--  -->
      <v-col cols="12" sm="12">
        <log-group
          :content="data.body"
          description="Dados enviados no corpo da requisição"
          title="Body"
          type="object"
          hide-if-empty
        />
      </v-col>

      <!--  -->
      <v-col cols="12" sm="12">
        <log-group
          :content="data.stack"
          type="string"
          description="Corpo do log"
          title="Stack"
          hide-if-empty
        />
      </v-col>
    </v-row>
  </KDialog>
</template>

<script>
import LogGroup from "./LogGroup";

import ChipCode from "./_shared/chip-code.vue";
import ChipMethod from "./_shared/chip-method.vue";
import ChipDate from "./_shared/chip-date.vue";

import KChip from "@/components/KChip";
import KDialog from "@/components/KDialog";

export default {
  components: {
    KChip,
    KDialog,

    LogGroup,
    ChipDate,
    ChipCode,
    ChipMethod
  },

  data() {
    return {
      // Define se está visível
      visible: false,
      loading: false,
      title: null,
      // Armazena os dados que virão via função para preencher
      data: {}
    };
  },

  computed: {
    // headers() {
    //   return this.data.headers && JSON.parse(this.data.headers);
    // }
  },

  methods: {
    /**
     * Abre o dialog preparado para alteerar os dados
     */
    openDetails(data) {
      // Mescla os dados recebidos para não alterar o que está para trás
      this.data = data;
      this.title = `Detalhes do Log #${data.id}`;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.visible = false;
      setTimeout(() => this.clear(), 200);
      // this.clear();
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.data = {};
    }
  }
};
</script>

<style>
</style>
