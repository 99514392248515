<template>
  <span>
    <!-- icon de comentário -->
    <v-tooltip bottom v-if="comments">
      <template v-slot:activator="{ on }">
        <v-icon size="16" v-on="on" color="grey" class="text--lighten-2"
          >mdi-comment-outline</v-icon
        >
      </template>
      {{ comments }}
    </v-tooltip>

    <!-- Ícone de imagem -->

    <v-tooltip bottom v-if="file_url">
      <template v-slot:activator="{ on }">
        <v-icon
          v-if="file_url"
          size="16"
          v-on="on"
          color="grey"
          class="text--lighten-2"
          >mdi-camera-outline</v-icon
        >
      </template>
      Foto Capturada
    </v-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    comments: String,
    file_url: String
  }
};
</script>

<style>
</style>
