<template>
  <KDialog
    show-close
    max-width="900px"
    :visible="visible"
    :title="`Visualizar Coleta #${form.id}`"
    :actions="buttons"
    @click:close="close"
    @click:next="onNextClick"
    @click:previous="onPreviousClick"
  >
    <CollectionView :collection="form" />
  </KDialog>
</template>

<script>
import KDialog from "@/components/KDialog";
import CollectionView from "./_shared/CollectionView";

export default {
  components: {
    KDialog,
    CollectionView
  },

  data() {
    return {
      visible: false,
      form: {},

      // BOtões da dialog
      buttons: [
        {
          eventName: "click:previous",
          label: "Anterior",
          icon: "mdi-chevron-left"
        },
        {
          eventName: "click:next",
          label: "Próximo",
          icon: "mdi-chevron-right",
          iconAfter: true
        }
      ]
    };
  },

  methods: {
    /**
     * Método de abertura do dialog
     */
    openToView(item) {
      this.form = item;
      this.visible = true;
    },

    /**
     * Ao clicar em navegar para o próximo
     */
    onNextClick() {
      // this.visible = false;
      this.$emit("click:next", this.form.id);
    },

    /**
     * Ao clicar em navegar para o anterior
     */
    onPreviousClick() {
      // this.visible = false;
      this.$emit("click:previous", this.form.id);
    },

    /**
     * Ao clicar em fechar
     */
    close() {
      this.visible = false;
    }
  }
};
</script>

<style>
</style>
