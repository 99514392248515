<template>
  <k-page fluid>
    <!-- Barra de busca -->
    <FilterBar :page="page" />

    <!--  -->
    <v-row no-gutters>
      <v-col>
        <v-card outlined>
          <v-list class="py-0 bordered">
            <!--  -->
            <v-list-item
              v-for="item in items"
              :key="`item-${item.id}`"
              :to="`/collect/condominiums/view/${item.id}`"
            >
              <v-list-item-avatar>
                <v-icon>mdi-city-variant</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  <AddressFormatter v-bind="item.address" />
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <KChip x-small blue v-if="!item.finished_at" class="mr-1"
                    >Ativo</KChip
                  >

                  <KChip x-small v-if="item.finished_at" class="mr-1"
                    >Inativo</KChip
                  >

                  <KChip x-small purple class="mr-1">
                    Razão Social:
                    {{ item.condominium && item.condominium.name }}
                  </KChip>
                  <KChip x-small orange class="mr-1"
                    >Síndico: {{ item.manager && item.manager.name }}</KChip
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- Barra de paginação -->
    <pagination-bar :pagination="pagination" v-model="page" />

    <!-- Separa o rodapé do botão de cadastrar -->
    <v-row style="height: 38px"></v-row>

    <!-- Botão FAB -->
    <SpeedDial :actions="speedDialActions" @click:add="onClickAdd" />
  </k-page>
</template>

 




<script>
import KPage from "@/components/KPage";
import SpeedDial from "@/components/SpeedDial";
import PaginationBar from "@/components/Pagination/Bar";
import KChip from "@/components/KChip";
import AddressFormatter from "@/components/AddressFormatter";

import FilterBar from "./FilterBar";

export default {
  components: {
    KPage,
    SpeedDial,
    PaginationBar,
    KChip,
    AddressFormatter,

    FilterBar
  },

  data() {
    return {
      //
      loading: false,

      //
      dialog: {
        visible: true
      },

      // Dados dos filtros
      query: {},

      page: 1,

      speedDialActions: [
        {
          eventName: "click:add",
          label: "Cadastrar Condomínio",
          icon: "mdi-city-variant"
        }
      ]
    };
  },
  watch: {
    pagination(val, old) {
      if (old.page != val.page) this.page = val.page;
    }
  },
  computed: {
    pagination() {
      return this.$store.getters["condominiums/pagination"];
    },
    items() {
      return this.$store.getters["condominiums/all"];
    }
  },

  //
  methods: {
    onClickAdd() {
      this.$router.push("/collect/condominiums/add");
    }
  }
};
</script>

