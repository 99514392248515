<template>
  <v-toolbar flat class="pl-2">
    <v-btn icon @click="filter()">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <!-- Pesquise pela Unidade -->
    <v-col class="pa-0">
      <v-text-field
        flat
        solo
        clearable
        hide-details
        class="mr-1"
        label="Pesquise pela Unidade"
        prepend-inner-icon="mdi-magnify"
        v-model="q"
        @keyup.enter.stop.prevent="filter(1)"
        @click:clear="onClear"
      ></v-text-field>
    </v-col>

    <!-- Filtrar por Condomínio -->
    <v-col class="pa-0">
      <v-autocomplete
        solo
        flat
        clearable
        hide-details
        class="mr-1"
        item-value="id"
        item-text="name"
        label="Filtrar por Condomínio"
        placeholder="Filtrar por Condomínio"
        prepend-inner-icon="mdi-filter-variant"
        v-model="query.condominium"
        @change="filter"
        :items="condominiums"
      ></v-autocomplete>
    </v-col>

    <!-- Filtrar por Status -->
    <v-col class="pa-0">
      <v-select
        solo
        flat
        clearable
        hide-details
        class="mr-1"
        item-value="value"
        item-text="text"
        label="Filtrar por Status"
        placeholder="Filtrar por Status"
        prepend-inner-icon="mdi-filter-variant"
        v-model="query.status"
        @change="filter"
        :items="statuses"
      ></v-select>
    </v-col>

    <!-- Filtro por mês -->
    <v-col class="pa-0">
      <KInputMonth
        label="Mês de referência"
        placeholder="Mês de referência"
        solo
        clearable
        flat
        hide-details
        v-model="month"
      />
    </v-col>
  </v-toolbar>
</template>

<script>
import KInputMonth from "@/components/KInput/Month";
export default {
  components: {
    KInputMonth
  },
  props: {
    page: { type: Number, default: 1 }
  },

  data() {
    return {
      query: {},
      q: null,
      month: null
    };
  },
  watch: {
    page() {
      this.filter();
    },
    query: {
      deep: true,
      handler() {
        this.filter(1);
      }
    },
    month(val) {
      if (val) {
        const [year, month] = val.split("-");
        this.$set(this.query, "year", year);
        this.$set(this.query, "month", Number(month));
      } else {
        this.$set(this.query, "year", null);
        this.$set(this.query, "month", null);
      }

      this.filter(1);
    }
  },

  computed: {
    condominiums() {
      return this.$store.getters["condominiums/all"];
    },
    filterData() {
      const payload = {
        page: this.page,
        q: this.q,
        ...this.query
      };

      Object.keys(payload).forEach(key => {
        if (payload.hasOwnProperty(key) && !payload[key]) {
          delete payload[key];
        }
      });

      return payload;
    },
    statuses() {
      return this.$store.getters["collections/statuses"];
    }
  },
  methods: {
    async filter(page = null) {
      try {
        const payload = this.filterData;

        if (page) payload.page = page;

        this.$loading.show();
        await this.$store.dispatch("collections/refresh", payload);
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    },

    /**
     * Ao limpar o campo de busca
     */
    onClear() {
      this.q = null;
      this.filter(1);
    }
  },

  mounted() {
    this.$store.dispatch("condominiums/all");
  }
};
</script>

<style>
</style>
