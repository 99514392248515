<template>
  <KDialog
    show-close
    :visible="visible"
    :loading="loading"
    :title="`Editar Coleta #${this.form.id}`"
    @click:close="visible = false"
    @click:edit="saveEdit"
    :actions="[{ eventName: 'click:edit', label: 'Editar Coleta' }]"
  >
    <KForm ref="form">
      <!-- Não está finalizado ainda -->
      <v-row class="ma-0">
        <v-col cols="12">
          <KAlert warning title="Atenção">
            Ao editar esta coleta, a imagem será mantida e o status dela será
            recalculado seguindo as mesmas regras da coleta.
          </KAlert>
        </v-col>
        <v-col cols="12" md="6">
          <KLabel label="Unidade de Consumo">
            {{ form.condominium_name }} - {{ form.unit_identification }}
          </KLabel>
        </v-col>
        <v-col cols="12" md="6">
          <KLabel label="Cliente">
            {{ form.customer_name }}
          </KLabel>
        </v-col>

        <!-- Imagem -->
        <v-col cols="12" sm="6" class="d-flex justify-center align-center">
          <v-card
            outlined
            width="100%"
            class="overflow-hidden d-flex justify-center align-center"
            height="100%"
            :max-width="$vuetify.breakpoint.xsOnly ? '300' : null"
          >
            <v-img
              v-if="form.file_url"
              :src="form.file_url"
              cover
              height="100%"
              width="100%"
            />
            <p v-else>Sem imagem</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="12">
              <KInputMoney
                dense
                label="Preço/m³"
                outlined
                prefix="R$"
                :precision="2"
                :rules="[$validation.isRequired]"
                v-model="form.unit_price"
                :error-messages="errors.unit_price"
              />
            </v-col>
            <v-col cols="12">
              <KLabel label="Coleta Anterior">
                {{
                  form.last_collect &&
                  form.last_collect.collected | precision(3)
                }}
              </KLabel>
            </v-col>
            <v-col cols="12">
              <KInputMoney
                dense
                label="Valor coletado"
                outlined
                :precision="3"
                suffix="m³"
                :rules="[$validation.isRequired]"
                v-model="form.collected_value"
                :error-messages="errors.collected_value"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Motivo da alteração"
                counter="250"
                outlined
                rows="2"
                v-model="form.comments"
                :error-messages="errors.comments"
                :rules="[
                  $validation.isRequired,
                  $validation.hasMaxLength(form.comments, '250'),
                ]"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KLabel from "@/components/KLabel";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
import KInputMoney from "@/components/KInput/Money";

export default {
  components: {
    KForm,
    KLabel,
    KAlert,
    KDialog,
    KInputMoney
  },

  data() {
    return {
      loading: false,
      visible: false,
      form: {},
      singleCollect: {},
      errors: {}
    };
  },

  methods: {
    async saveEdit() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          const { data } = await this.$api.put(
            `/collections/${this.form.id}`,
            this.form
          );

          this.visible = false;
          this.loading = false;
          this.form = {};

          // console.log("resultado da redefinição", result);

          this.$emit("save", data.data);
        }
      } catch (error) {
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    openToEdit(payload) {
      this.form = { ...payload, comments: null };

      this.visible = true;
    }
  }
};
</script>

<style>
</style>
