<template>
  <KDialog
    show-close
    :visible="visible"
    :loading="loading"
    :title="`Cancelar Coleta #${this.form.id}`"
    @click:close="visible = false"
    @click:cancel="saveCancel"
    :actions="[
      { eventName: 'click:cancel', label: 'Cancelar Coleta', color: 'red' },
    ]"
  >
    <KForm ref="form">
      <!-- Não está finalizado ainda -->
      <v-row class="ma-0">
        <v-col cols="12">
          <v-textarea
            label="Motivo do cancelamento"
            counter="250"
            outlined
            v-model="form.comments"
            :error-messages="errors.comments"
            :rules="[
              $validation.isRequired,
              $validation.hasMaxLength(form.comments, '250'),
            ]"
          ></v-textarea>
        </v-col>
      </v-row>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KDialog from "@/components/KDialog";

export default {
  components: {
    KForm,
    KDialog
  },

  data() {
    return {
      loading: false,
      visible: false,
      form: {},
      singleCollect: {},
      errors: {}
    };
  },

  methods: {
    async saveCancel() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          // const result = await this.$store.dispatch(
          //   "collections/cancel",
          //   this.form
          // );

          const { data } = await this.$api.patch(
            `/collections/${this.form.id}/cancel`,
            this.form
          );
          // console.log(data);

          this.visible = false;
          this.loading = false;
          this.form = {};
          this.$emit("save", data.data);
        }
      } catch (error) {
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    openToCancel(id) {
      this.form = { id, comments: null };

      this.visible = true;
    }
  }
};
</script>

<style>
</style>
