<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateBR"
        prepend-inner-icon="mdi-calendar"
        readonly
        :clearable="clearable"
        @click:clear="onClear"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
      ></v-text-field>
    </template>
    <!--  -->
    <v-date-picker
      v-model="dateString"
      type="month"
      no-title
      scrollable
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "k-input-month",

  props: {
    // Recebe a data no formato "2022-03" ou {year:2022, month:3}
    value: [String, Object],
    // Força o início do campo com o mês/ano atual
    mandatory: Boolean,
    // Permite que o valor seja limpo, caso não seja mandatory
    clearable: Boolean,
    // Define se o valor do input deste campo deve ser como objeto
    returnObject: Boolean
  },

  data: () => ({
    date: null, // Data usada no v-model
    dateString: null, // Uma data que sempre está como string
    dateObject: {}, // Uma data que sempre está como object
    menu: false // Mostra o menu
  }),

  watch: {
    value(value) {
      this.date = value;
    },
    date() {
      this.$refs.menu.save(this.date);
      this.dateString = this.toString(this.date);
      this.dateObject = this.toObject(this.date);

      if (this.returnObject) {
        this.$emit("input", this.dateObject);
      } else {
        this.$emit("input", this.dateString);
      }
    },
    dateString(value) {
      this.date = value;
    }
  },

  computed: {
    dateBR: {
      get: function() {
        if (this.date) {
          return this.toFullDateBR(this.date);
        } else {
          return null;
        }
      },
      set: function(a) {
        // this.date = a;
      }
    }
  },
  methods: {
    /**
     * Retorna a data atual no formato 2022-05
     */
    currentMonth() {
      const date = new Date();
      const fullMonth = String(date.getMonth() + 1).padStart(2, "0");
      return `${date.getFullYear()}-${fullMonth}`;
    },

    /**
     * Retorna a data no formato BR
     *
     * @example
     *
     * toFullDateBR('2022-05') // Maio de 2022
     * toFullDateBR({year: 2022, month: 5}) // Maio de 2022
     *
     * @param {String|Object} value
     */
    toFullDateBR(value) {
      const months = [
        "",
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ];

      const { year, month } = this.toObject(value);

      const toFullString = (year, month) =>
        `${months[Number(month)]} de ${year}`;

      if (year && month) {
        return toFullString(year, month);
      }

      return null;
    },

    /**
     * Retorna a data como object
     *
     * @example
     * toObject(null)                     // null
     * toObject('2022-5')                 // { year: 2022, month: 5 }
     * toObject('2022-05')                // { year: 2022, month: 5 }
     * toObject({ year: 2022, month: 5 }) // { year: 2022, month: 5 }
     *
     */
    toObject(value) {
      if (value) {
        if (typeof value && value.year && value.month) {
          return value;
        } else if (typeof value === "string" && this.isDateString(value)) {
          const [year, month] = value.split("-");
          return { year: Number(year), month: Number(month) };
        }
      }

      return null;
    },

    /**
     * Converte a data para o formato year
     *
     * @example
     * toString(null)                     // -> null
     * toString('2022-5')                 // -> 2022-05
     * toString('2022-05')                // -> 2022-05
     * toString({ year: 2022, month: 5 }) // -> 2022-05
     */
    toString(value) {
      if (value) {
        const { year, month } = this.toObject(value);
        return `${year}-${String(month).padStart(2, "0")}`;
      }

      return null;
    },

    /**
     *
     */
    isDateString(value) {
      return value && String(value).match(/\d{4}-\d{1,2}/);
    },

    /////////////
    // EVENTS
    /////////////
    onClear() {
      this.date = null;
    }
  },

  mounted() {
    if (this.mandatory && !this.value) {
      this.date = this.currentMonth();
    } else if (this.value) {
      this.date = this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
