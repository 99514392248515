<!--
  Componente que exibe um JSON no formato string como uma lista
  de dados. 

  @Hot to use
  <log-value :content="log.headers" :truncate-text="true" />

  @Attributes

  content {String} required - default: ''
  - O conteúdo propriamente dito. Este conteúdo será um string em formato JSON
    que será convertida para objeto dentro da função


  truncate-big-texts {Boolean} optional - default: false
  - Define se o texto grande será truncado ou ocupará várias linhas
  - Se truncate for true, exiba um botão no cabeçalho para expandir


-->
<template>
  <div class="log-list-value">
    <!-- Conteúdo -->
    <pre :class="{truncate: truncateBigTexts}">
    {{content}}
    </pre>
    <!-- Divisor -->
    <v-divider></v-divider>
  </div>
</template>

<script>
import { isArray, isObject, isString } from "@/plugins/utils";
export default {
  name: "log-value",
  props: {
    // Conteúdo em string no formato JSON ou Array
    content: { required: true }, //ok
    // Define se o texto grande será truncado ou ocupará várias linhas
    truncateBigTexts: { type: Boolean, default: true }
  },
  data: () => ({}),
  computed: {},
  methods: {},
  mounted() {}
};
</script>

<style scoped>
.log-list-value pre {
  width: 100%;
  overflow: auto;
  user-select: text;
}

.log-list-value .truncate {
  max-height: 300px;
}
</style>