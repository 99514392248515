<template>
  <k-page>
    <!-- Botões -->
    <v-row class="mx-0 my-3" v-if="isEditing">
      <v-col cols="12">
        <SectionHeader title="Estatísticas" />
      </v-col>
      <v-col class="pa-0 mb-4" v-for="item in statsList" :key="item.title">
        <CardInfo v-bind="item" />
      </v-col>
    </v-row>

    <!-- Formulário -->
    <k-form ref="form" v-if="!loading">
      <v-container>
        <SectionHeader title="Dados do condomínio" />

        <v-row class="my-4">
          <!-- Nome do Condomínio -->
          <v-col cols="12" class="py-1">
            <v-text-field
              dense
              outlined
              :rules="[$validation.isRequired]"
              :error-messages="errors.name"
              label="Nome do Condomínio"
              hint="Nome fantasia deste condomínio"
              persistent-hint
              v-model="form.name"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <SectionHeader
          title="Pessoas Relacionadas"
          :actions="[
            {
              to: `/people/add`,
              target: '_blank',
              icon: 'mdi-plus',
              label: 'Cadastrar nova pessoa',
            },
          ]"
        />

        <v-row class="my-0">
          <!-- Pessoa Jurídica -->
          <v-col cols="12" md="6">
            <PersonInfoSelect
              outlined
              dense
              label="Pessoa Jurídica do Condomínio"
              :person="form.condominium"
              :error-messages="errors.condominium_id"
              :search-params="{ showRoles: '', showAddresses: '1' }"
              v-model="form.condominium_id"
              @select="onSelectCondominiumPerson"
              @clear="onClearCondominiumPerson"
            />
          </v-col>

          <!-- Síndico -->
          <v-col cols="12" md="6">
            <PersonInfoSelect
              outlined
              dense
              label="Pessoa Física do Síndico"
              :person="form.manager"
              :error-messages="errors.manager_id"
              :search-params="{ showRoles: '', showAddresses: true }"
              v-model="form.manager_id"
              @clear="form.manager_id = {}"
            />
          </v-col>

          <!-- Endereço -->
          <v-col cols="12" class="my-4">
            <KInputSelect
              dense
              outlined
              label="Endereço do Condomínio"
              hint="A lista de endereços será exibida quando a pessoa jurídica for selecionada"
              persistent-hint
              no-data-text="Nenhum endereço cadastrado para a pessoa jurídica do condomínio"
              v-model="form.address_id"
              item-text="description"
              item-subtext="address"
              item-value="id"
              :items="addressesFull"
            />
          </v-col>
        </v-row>

        <!-- Configurações de coleta -->
        <SectionHeader title="Configurações de Coleta" />

        <v-row class="my-4">
          <!-- Porcentagem de Lucro -->
          <v-col cols="12" md="4" class="py-0">
            <KInputMoney
              label="Porcentagem de Lucro"
              dense
              outlined
              v-model="form.profit_percentage"
              :error-messages="errors.profit_percentage"
              suffix="%"
            />
          </v-col>

          <!-- Custo de Cobrança -->
          <v-col cols="12" md="4" class="py-0">
            <KInputMoney
              label="Custo de Cobrança"
              dense
              outlined
              prefix="R$"
              hint="Custo de geração da cobrança, como impressão do boleto, coleta etc."
              persistentHint
              v-model="form.payment_cost"
              :error-messages="errors.payment_cost"
            />
          </v-col>

          <!-- Produto Fornecido -->
          <v-col cols="12" md="4" class="py-0">
            <v-select
              label="Produto Fornecido"
              dense
              outlined
              v-model="form.product_id"
              :error-messages="errors.product_id"
              :items="products"
              item-text="name"
              item-value="id"
              hint="Selecione o produto de fornecimento definido no contrato deste condomínio"
              persistent-hint
            />
          </v-col>
        </v-row>

        <!-- Dados do Contrato -->
        <SectionHeader title="Dados do Contrato" />

        <v-row class="my-4">
          <!-- Data de Início do Contrato -->
          <v-col cols="12" md="6" class="py-0">
            <KInputDate
              label="Data de Início do Contrato"
              dense
              outlined
              :rules="[]"
              v-model="form.started_at"
              :error-messages="errors.started_at"
            />
          </v-col>

          <!-- Data de Finalização do Contrato -->
          <v-col cols="12" md="6" class="py-0">
            <KInputDate
              label="Data de Finalização do Contrato"
              dense
              outlined
              :rules="[]"
              v-model="form.finished_at"
              :error-messages="errors.finished_at"
            />
          </v-col>
        </v-row>

        <!-- 

          Edição

        -->
        <template v-if="isEditing">
          <!-- Grupos -->
          <SectionHeader
            title="Arquivos e Informações"
            subtitle="Grupos de anexos e dados relativos ao condomínio"
            :actions="[
              {
                to: '/grouping',
                icon: 'mdi-plus',
                label: 'Cadastrar novo grupo',
              },
              {
                icon: 'mdi-refresh',
                eventName: 'click:refresh',
                label: 'Atualizar a lista de grupos',
              },
            ]"
            @click:refresh="handleRefreshGroupClick"
          />

          <GroupsList
            reference_module="condominium"
            :reference_module_id="form.id"
            ref="groups-list"
          />
        </template>
      </v-container>

      <!--  -->
      <v-row class="ma-0 mb-8" align="center" justify="center">
        <v-btn color="accent" rounded elevation="0" @click="save">Salvar</v-btn>
      </v-row>
    </k-form>
  </k-page>
</template>

<script>
import KForm from "@/components/KForm";
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import StatsLinkItem from "@/components/Stats/LinkItem";
import PersonInfoSelect from "@/components/PersonInfoSelect";
import KInputDate from "@/components/KInput/Date";
import KInputMoney from "@/components/KInput/Money";
import KInputSelect from "@/components/KInput/Select";

import GroupsList from "@/views/Grouping/Children/List";
import CardInfo from "@/components/CardInfo";

import addressFormatter from "@/components/AddressFormatter/formatter";

export default {
  name: "page-save",

  components: {
    // VuetifyMoney,

    KForm,
    KPage,
    SectionHeader,
    StatsLinkItem,
    PersonInfoSelect,
    KInputDate,
    KInputMoney,
    KInputSelect,

    GroupsList,
    CardInfo
  },

  data() {
    return {
      form: {},
      stats: {},
      errors: [],
      addresses: [],
      loading: false
    };
  },

  computed: {
    isEditing() {
      return !!this.$route.params.condominium_id;
    },

    addressesFull() {
      // console.log(this.addresses);
      if (!this.addresses) return [];

      return this.addresses.map(item => ({
        ...item,
        address: addressFormatter(item)
      }));
    },

    products() {
      return this.$store.getters["products/all"];
    },

    //
    statsList() {
      return [
        {
          label: "Contratos de Consumo",
          value: this.stats.contracts,
          icon: "mdi-file-sign",
          isActive: true,
          to: `/collect/condominiums/${this.$route.params.condominium_id}/consumption_contracts`
        },
        {
          label: "Solicitações Coleta",
          value: this.stats.collections,
          icon: "mdi-cellphone-marker",
          isActive: true,
          to: `/collect/condominiums/${this.$route.params.condominium_id}/collections`
        },
        {
          label: "Abastecimentos",
          value: this.stats.supplies,
          icon: "mdi-gas-cylinder",
          isActive: true,
          to: `/collect/condominiums/${this.$route.params.condominium_id}/supplies`
        }
      ];
    }
  },

  methods: {
    /**
     *
     *
     */
    async save() {
      try {
        this.$loading.show();
        this.errors = {};
        // console.log(this.form);
        const payload = {
          id: this.form.id,
          name: this.form.name,
          condominium_id: this.form.condominium_id,
          manager_id: this.form.manager_id,
          address_id: this.form.address_id,
          product_id: this.form.product_id,
          profit_percentage: this.form.profit_percentage,
          payment_cost: this.form.payment_cost,
          started_at: this.form.started_at,
          finished_at: this.form.finished_at
        };

        const condominium = await this.$store.dispatch("condominiums/save", {
          payload,
          editing: this.isEditing
        });

        this.$loading.hide();
        this.$message.success("Registro salvo com sucesso");
        if (!this.isEditing) {
          // console.log("é edita", condominium);
          this.$router.push(`/collect/condominiums/view/${condominium.id}`);
        }
      } catch (error) {
        this.$loading.hide();
        this.errors = this.$message.serverError(error);
        // console.log("erro ao salvar", errors);
        console.log(error);
      }
    },

    /**
     *
     *
     */
    onSelectCondominiumPerson(person) {
      const { addresses, ...condominium } = person;
      this.addresses = addresses;
    },

    /**
     *
     */
    onClearCondominiumPerson() {
      this.form.condominium_id = {};
      this.addresses = [];
    },

    /**
     *
     *
     */
    async load() {
      this.$loading.show();
      this.loading = true;

      const { data } = await this.$api.get(
        `/condominiums/${this.$route.params.condominium_id}`
      );

      this.form = data.data;
      this.onSelectCondominiumPerson(data.data.condominium);
      this.loadAddressByPerson(data.data.condominium.id);
      //
      this.$app.title(`${data.data.name}`);

      this.$loading.hide();
      this.loading = false;
    },

    /**
     *
    //  */
    async loadAddressByPerson(personId) {
      try {
        const { data } = await this.$api.get(`/people/${personId}/addresses`);
        this.addresses = data.data;
      } catch (error) {
        this.$message.error("Erro ao carregar os endereços");
      }
    },

    refreshAddress() {
      this.loadAddressByPerson(this.form.condominium_id);
    },

    /**
     *
     */
    async loadStats() {
      try {
        const { data } = await this.$api.get(
          `/condominiums/${this.$route.params.condominium_id}/stats`
        );
        this.stats = data.data;
      } catch (error) {
        console.log(error);
        this.$message.serverError(error);
      }
    },

    handleRefreshGroupClick() {
      this.$refs["groups-list"].loadItems();
    }
  },

  async beforeMount() {
    if (this.isEditing) {
      await Promise.all([this.load(), this.loadStats()]);
    }

    this.$store.dispatch("products/all");
  }
};
</script>

<style>
</style>

