<template>
  <v-col class="mb-0 mr-0" :style="{ '--link-color': primaryColor }">
    <graphic-info
      elevation="0"
      :color="calcBGColor"
      :sheet-color="calcBGColor"
      small
      :title="title"
      :to="to"
      min-width="200"
    >
      <div
        style="color: grey"
        :class="{ 'link-item-color': active }"
        class="d-flex align-center justify-center mx-2"
      >
        <v-icon
          v-if="icon"
          color="grey"
          :class="{ 'link-item-color': active }"
          size="36"
          class="mr-5"
          >{{ icon }}</v-icon
        >
        <span v-if="value" class="display-1" style="line-height: 36px">{{
          value
        }}</span>
      </div>
    </graphic-info>
  </v-col>
</template>

<script>
import GraphicInfo from "@/components/Graphic/Info.vue";
export default {
  name: "link-item",
  components: { GraphicInfo },
  props: {
    title: String,
    icon: String,
    value: [Number, String],
    active: Boolean,
    to: String,
    color: String,
    backgroundColor: String
  },
  computed: {
    isDarkOrLight() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    primaryColor() {
      const theme = this.$vuetify.theme.themes[this.isDarkOrLight];
      return this.color || theme.primary;
    },
    calcBGColor() {
      return (
        this.backgroundColor ||
        (this.isDarkOrLight === "dark" ? "neutral" : "white")
      );
    }
  }
};
</script>

<style scoped>
.link-item-color {
  color: var(--link-color) !important;
}
</style>