<template>
  <KChip
    :medium="medium"
    icon="mdi-clock-outline"
    :title="date | dateFullBR"
  >{{ date | timeAgoFull }}</KChip>
</template>
  

<script>
import KChip from "@/components/KChip";

export default {
  name: "chip-date",
  props: {
    date: { required: true },
    medium: Boolean
  },
  components: {
    KChip
  }
};
</script>
