<template>
  <v-row class="ma-0">
    <!-- Lote -->
    <KChip class="my-1" :medium="medium" v-if="batch_id" cyan>{{
      monthYear
    }}</KChip>

    <!-- número do lote -->
    <KChip class="my-1" :medium="medium" v-if="batch_id" pink
      >Lote {{ batch_id }}</KChip
    >

    <!-- coletado em -->
    <KChip
      class="my-1"
      :medium="medium"
      v-if="['collected', 'ignored'].includes(status)"
      green
      >Coletado {{ collected_at | dateFullBR }}</KChip
    >

    <!-- Abaixo do mínimo -->
    <KChip class="my-1" :medium="medium" v-if="status === 'ignored'" red
      >Abaixo do mínimo</KChip
    >

    <!-- Coleta pendente -->
    <KChip class="my-1" :medium="medium" v-if="status === 'pendent'" orange
      >Coleta pendente</KChip
    >

    <!-- Cancelada -->
    <KChip class="my-1" :medium="medium" v-if="status === 'cancelled'" grey
      >Cancelada</KChip
    >

    <KChip class="my-1" :medium="medium" v-if="is_initial" purple
      >Coleta inicial</KChip
    >

    <!-- Chips adicionados no pai -->
    <slot></slot>
  </v-row>
</template>

<script>
import KChip from "@/components/KChip";

export default {
  name: "ChipsList",

  components: {
    KChip
  },

  props: {
    status: String,
    collected_at: String,
    is_initial: [String, Number],
    batch_id: [Number],
    batch_month: [Number],
    batch_year: [Number],
    medium: Boolean
  },

  computed: {
    monthYear() {
      const months = [
        "",
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ];

      if (this.batch_month) {
        return `${months[this.batch_month]}/${this.batch_year}`;
      }
    }
  }
};
</script>

<style>
</style>
