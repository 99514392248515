<template>
  <span> {{ address }} </span>
</template>

<script>
import formatter from "./formatter";
export default {
  name: "address-formatter",

  props: {
    street: String,
    number: String,
    complement: String,
    district: String,
    city: String,
    state: String,
    zipcode: String
  },

  computed: {
    address() {
      return formatter({
        street: this.street,
        number: this.number,
        complement: this.complement,
        district: this.district,
        city: this.city,
        state: this.state,
        zipcode: this.zipcode
      });
    }
  }
};
</script>

<style>
</style>
