<template>
  <v-row class="my-4">
    <v-col cols="12" class="py-1">
      <SectionHeader title="Condomínio" />
    </v-col>
    <v-col cols="12">
      <v-card outlined>
        <v-card-title>{{ condObj.name }}</v-card-title>
        <v-card-subtitle>
          <slot></slot>
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";

export default {
  components: {
    SectionHeader
  },

  props: {
    condominium: { type: Object, default: null }
  },

  data() {
    return {
      result: {}
    };
  },

  computed: {
    condObj() {
      if (this.condominium && this.condominium.name) {
        return this.condominium;
      } else if (this.$route.params.condominium_id) {
        return this.result;
      }

      return {};
    }
  },

  methods: {
    async loadCondominum() {
      try {
        if (this.$route.params.condominium_id) {
          const { data } = await this.$api.get(
            `/condominiums/${this.$route.params.condominium_id}`
          );

          this.result = data.data;
          this.$emit("load", data.data);
        }
      } catch (error) {
        this.$message.serverError(error);
      }
    }
  },

  mounted() {
    this.loadCondominum();
  }
};
</script>

<style>
</style>
