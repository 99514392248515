<template>
  <KChip v-bind="type" :medium="medium">{{ method }}</KChip>
</template>

<script>
import KChip from "@/components/KChip";

export default {
  name: "chip-method",
  props: {
    method: { required: true },
    medium: Boolean
  },
  components: {
    KChip
  },

  computed: {
    type() {
      const types = {
        GET: { purple: true },
        POST: { green: true },
        PUT: { amber: true },
        DELETE: { red: true },
        SMTP: { pink: true }
      };

      return types[this.method];
    }
  }
};
</script>

<style>
</style>
