<template>
  <KDialog
    show-close
    :visible="visible"
    :loading="loading"
    :title="`Resetar Coleta #${this.form.id}`"
    @click:close="visible = false"
    @click:reset="saveReset"
    :actions="[
      { eventName: 'click:reset', label: 'Resetar Coleta', color: 'red' },
    ]"
  >
    <KForm ref="form">
      <!-- Não está finalizado ainda -->
      <v-row class="ma-0">
        <v-col cols="12">
          <KAlert warning title="Atenção">
            Ao resetar esta coleta, a imagem será removida e o status dela
            voltará a ser pendente.
          </KAlert>
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Motivo da redefinição"
            counter="250"
            outlined
            v-model="form.comments"
            :error-messages="errors.comments"
            :rules="[
              $validation.isRequired,
              $validation.hasMaxLength(form.comments, '250'),
            ]"
          >
          </v-textarea>
        </v-col>
      </v-row>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";

export default {
  components: {
    KForm,
    KAlert,
    KDialog
  },

  data() {
    return {
      loading: false,
      visible: false,
      form: {},
      singleCollect: {},
      errors: {}
    };
  },

  methods: {
    async saveReset() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          const { data } = await this.$api.patch(
            `/collections/${this.form.id}/reset`,
            this.form
          );

          this.visible = false;
          this.loading = false;
          this.form = {};

          // console.log("resultado da redefinição", result);

          this.$emit("save", data.data);
        }
      } catch (error) {
        console.log(error);
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    openToReset(id) {
      this.form = { id, comments: null };

      this.visible = true;
    }
  }
};
</script>

<style>
</style>
