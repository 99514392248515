<template>
  <KChip
    v-if="code"
    :medium="medium"
    :title="'Http Status Code ' + code"
    v-bind="classes"
  >{{ code }}</KChip>
</template>

<script>
import KChip from "@/components/KChip";

export default {
  name: "chip-code",

  props: {
    code: { required: true },
    medium: Boolean
  },

  components: {
    KChip
  },

  computed: {
    classes() {
      const firstNumber = String(this.code)[0];
      const types = {
        1: { blue: true },
        2: { green: true },
        3: { yellow: true },
        4: { orange: true },
        5: { red: true }
      };

      return types[firstNumber];
    }
  }
};
</script>

<style>
</style>
