    <!-- <div class="d-block text-no-wrap">{{ pagination.total }} resultados</div> -->
<template>
  <span class="text-body-2">{{ result }}</span>
</template>

<script>
export default {
  props: {
    pagination: Object
  },
  computed: {
    result() {
      try {
        const { lastPage, page, perPage, total } = this.pagination;
        const multiplier = page <= 1 ? 0 : page - 1;

        if (!total) return "Sem resultados";

        // Mostrando os resultados do início.
        // Se existirem resultados a exibir, o valor é o número de resultados por página
        // multiplicado pelo multiplicador + 1
        const start = perPage * multiplier + 1;

        // Mostrando resultado final
        // Se existirem resultados, verificar se está na última página. Se estiver na última página
        // exiba somente o total, que é o último.
        const final = page === lastPage ? total : perPage * page;

        return `${start}-${final} de ${total}`;
        //
      } catch (error) {
        return "Sem resultados";
      }
    }
  }
};
</script>
