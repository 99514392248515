<template>
  <v-list-item @click.stop="open(log)">
    <!--  -->
    <v-list-item-content class="py-0">
      <v-list-item-title>
        <!-- ID -->
        <v-chip x-small outlined class="mr-1"># {{ log.id }}</v-chip>

        <!-- Status -->
        <span class="font-weight-regular">{{ log.status }}</span> -
        <span class="font-weight-light font-italic gray--text">
          {{ log.message }}
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <chip-date :date="log.created_at" />
        <!-- Code -->
        <chip-code v-if="log.code" :code="log.code" />
        <!-- Method -->
        <chip-method v-if="log.method" :method="log.method" />
        <!-- Tipo do erro -->
        <KChip x-small green class="mr-1">{{ log.type }}</KChip>
        <!-- Origem -->
        <KChip v-if="log.origin" x-small blue class="mr-1">
          {{ log.origin }}
        </KChip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import ChipCode from "./_shared/chip-code.vue";
import ChipMethod from "./_shared/chip-method.vue";
import ChipDate from "./_shared/chip-date.vue";
import KChip from "@/components/KChip";

export default {
  name: "item",
  props: { log: { type: Object, default: () => ({}) } },
  components: {
    KChip,

    ChipCode,
    ChipMethod,
    ChipDate
  },
  data: () => ({}),
  methods: {
    open(log) {
      this.$emit("click:open", log);
    }
  }
};
</script>

<style>
</style>
