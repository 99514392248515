<template>
  <div>
    <!-- Toolbar -->
    <v-row class="mt-3">
      <v-col>
        <ChipsList medium v-bind="collection" class="flex">
          <KChip medium blue class="my-1">{{ collection.product_name }}</KChip>
        </ChipsList>
      </v-col>
    </v-row>

    <!-- Dados da Coleta -->
    <v-row>
      <!-- Imagem -->
      <v-col cols="12" md="6">
        <SectionHeader title="Imagem" />
        <v-responsive :aspect-ratio="1 / 1" class="align-center justify-center">
          <!-- Se não tiver imagem -->
          <p class="text-center" v-if="!collection.file_url">Sem Imagem</p>
          <!-- Se tiver imagem -->
          <v-card outlined class="overflow-hidden" v-if="collection.file_url">
            <v-img :aspect-ratio="1 / 1" :src="collection.file_url" />
          </v-card>
        </v-responsive>
      </v-col>

      <!-- Informações -->
      <v-col cols="12" md="6">
        <SectionHeader title="Coleta Atual" />
        <v-row class="my-0">
          <!--  -->
          <v-col cols="12" class="">
            <KLabel label="Número do Medidor">
              {{ collection.meter_number }}
            </KLabel>
          </v-col>

          <!--  -->
          <v-col cols="12" md="6" class="">
            <KLabel label="Leitura Atual"
              >{{ collection.collected_value | precision(3) }} m³</KLabel
            >
          </v-col>

          <!--  -->
          <v-col cols="12" md="6" class="">
            <KLabel label="Leitura Anterior">
              {{
                ((collection.last_collect &&
                  collection.last_collect.collected) ||
                  0) | precision(3)
              }}
              m³
            </KLabel>
          </v-col>

          <!--  -->
          <v-col cols="12" md="6" class="">
            <KLabel label="Consumo Atual">
              {{ (collection.consumed_value || 0) | precision(3) }} m³
            </KLabel>
          </v-col>

          <!--  -->
          <v-col cols="12" md="6" class="">
            <KLabel label="Consumo Anterior">
              {{
                ((collection.last_collect &&
                  collection.last_collect.consumed) ||
                  0) | precision(3)
              }}
              m³
            </KLabel>
          </v-col>

          <!--  -->
          <v-col cols="12" md="6" class="">
            <KLabel label="Valor/m³">{{
              collection.unit_price | currency
            }}</KLabel>
          </v-col>

          <!--  -->
          <v-col cols="12" md="6" class="">
            <KLabel label="Valor Consumido">{{
              (collection.unit_price * collection.consumed_value) | currency
            }}</KLabel>
          </v-col>

          <!-- Comentário -->
          <v-col cols="12" class="py-1">
            <SectionHeader title="Comentário" />
          </v-col>

          <!--  -->
          <v-col cols="12" class="">
            <KLabel label="Comentário" :lines="2">
              {{ collection.comments }}
            </KLabel>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Mensagem de valor muito acima -->
    <v-row v-if="false">
      <v-col>
        <KAlert warning title="Atenção">
          Esta coleta excedeu em 30% a coleta anterior. Verifique se o valor
          registrado está correto.
        </KAlert>
      </v-col>
    </v-row>

    <!-- Dados do condomínio e do contrato de consumo -->
    <v-row>
      <!-- Dados do Condomínio -->
      <v-col cols="12" md="6">
        <SectionHeader
          title="Dados do Condomínio"
          :actions="[
            {
              // Ícone que será exibido
              icon: 'mdi-open-in-new',
              // Exibe um texto de ajuda
              label: 'Abrir página do condomínio',
              // Todos os parâmetros a seguir serão passados diretamente para o botão
              to: '/collect/condominiums/view/' + collection.condominium_id,
              target: '_blank',
            },
          ]"
        />
        <v-card outlined style="height: calc(100% - 36px)">
          <v-card-title class="text-subtitle-1 py-1">
            {{ collection.condominium_name }}
          </v-card-title>
          <v-card-text class="d-flex flex-row">
            <AddressFormatter
              :street="collection.condominium_address_street"
              :number="collection.condominium_address_number"
              :district="collection.condominium_address_district"
              :city="collection.condominium_address_city"
              :state="collection.condominium_address_state"
              :zipcode="collection.condominium_address_zipcode"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Dados da Unidade -->
      <v-col cols="12" md="6">
        <SectionHeader
          title="Dados da Unidade"
          :actions="[
            {
              // Ícone que será exibido
              icon: 'mdi-open-in-new',
              // Exibe um texto de ajuda
              label: 'Abrir página da Unidade',
              // Todos os parâmetros a seguir serão passados diretamente para o botão
              to: `/collect/condominiums/${collection.condominium_id}/consumption_contracts/view/${collection.contract_id}`,
              target: '_blank',
            },
          ]"
        />
        <v-card outlined style="height: calc(100% - 36px)">
          <v-card-title class="text-subtitle-1 py-1">
            {{ collection.unit_identification }}
          </v-card-title>
          <v-card-text class="d-flex flex-row">
            {{ collection.customer_name }}
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Dialog dos Logs -->
      <DialogList ref="dialog_logs_list" />
    </v-row>
  </div>
</template>

<script>
import KChip from "@/components/KChip";
import KAlert from "@/components/KAlert";
import SectionHeader from "@/components/SectionHeader";
import AddressFormatter from "@/components/AddressFormatter";

import KLabel from "@/components/KLabel";
import ChipsList from "./ChipsList";

import DialogList from "@/views/Logs/DialogList";

export default {
  components: {
    ChipsList,
    KChip,
    SectionHeader,
    KLabel,
    KAlert,
    AddressFormatter,
    DialogList,
  },

  props: {
    collection: Object,
  },
};
</script>

<style>
</style>
