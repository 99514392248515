<template>
  <fieldset class="k-label text-body-2 relative overflow-hidden">
    <legend class="caption">{{ label }}</legend>
    <div :class="{ truncate, lines }" :style="styles">
      <slot></slot>
    </div>
  </fieldset>
</template>

<script>
export default {
  props: {
    label: String,
    // Limita o número de linhas
    lines: [String, Number],
    // Trunca o valor
    truncate: Boolean
  },

  data() {
    return {
      // ref: Math.round(Math.random() * 999 + 1)
      width: 100
    };
  },

  computed: {
    styles() {
      // Se tiver width
      const styles = {};

      if (this.truncate && this.width) styles.width = `${this.width}px`;
      if (this.lines) styles["-webkit-line-clamp"] = this.lines;

      return styles;
    }
  },
  methods: {
    setWidth() {
      try {
        if (this.truncate) {
          if (this.$el) {
            // const { parentNode } = this.$el;
            if (!this.parentNode) {
              this.parentNode = this.$el.parentNode;
            }
            const parentStyles = window.getComputedStyle(this.parentNode, null);

            const getRealWidth = prop => {
              const val = parentStyles.getPropertyValue(prop);
              // console.log("val", val);
              // console.log("val reaplced", Number(val.replace(/(px)/, "")));
              return Number(val.replace(/(px)/, "")).toFixed(0);
            };

            const width =
              getRealWidth("width") -
              getRealWidth("padding-left") -
              getRealWidth("padding-right") -
              4 -
              /* padding direito*/ 10; /* padding esquerdo */

            // console.log("width", width);

            this.width = width;
          }
        }
      } catch (error) {}
    }
  },
  mounted() {
    this.setWidth();
    window.addEventListener("resize", () => this.setWidth());
  },
  updated() {
    setTimeout(this.setWidth, 50);
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => this.setWidth());
  }
};
</script>

<style scoped>
.theme--dark .k-label {
  --border-color: rgba(255, 255, 255, 0.12);
}
.theme--light .k-label {
  --border-color: rgba(0, 0, 0, 0.12);
}

fieldset.k-label {
  border: thin solid var(--border-color);
  border-radius: 4px;
  margin-top: -10px;
  padding-top: 0;
  padding-bottom: 6px;
  padding-right: 4px;
  padding-left: 10px;
  min-height: 50px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lines {
  /**Major Properties**/
  overflow: hidden;

  /* white-space: nowrap; */
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  /* -webkit-line-clamp: 4;  /* repassada para os estilos  */
}
</style>
