<template>
  <v-text-field
    type="number"
    inputmode="numeric"
    hide-spin-buttons
    ref="input"
    v-model="model"
    v-bind="$attrs"
    @keydown="onKeyDown"
    @keyup="$emit('keyup', $event)"
  />
</template>

<script>
export default {
  props: {
    value: [Number, String],
    precision: { type: Number, default: 2 }
  },

  data() {
    return {
      model: null
    };
  },

  watch: {
    value(newValue) {
      if (newValue === null) {
        this.model = 0;
      } else {
        this.model = Number(this.value).toFixed(this.precision);
      }
    },
    model(newValue) {
      const cleared = this.clearNumber(newValue);
      this.model = cleared;
      this.$emit("input", cleared);
    }
  },

  methods: {
    clearNumber(value) {
      return (
        Number(String(value).replace(/[^\d]+/, "") || 0) /
        Math.pow(10, this.precision)
      ).toFixed(this.precision);
    },
    /**
     * Ao apartar a tecla, antes de gravar o valor, verifique
     * se o valor já é 0 e se é uma tecla de apagar.
     * Caso seja, cancele o evento.
     */
    onKeyDown(e) {
      const num = Number(this.value);
      if (num === 0 && ["Backspace", "Delete"].includes(e.key)) {
        e.preventDefault();
      }
    }
  },

  beforeMount() {
    this.model = Number(this.value).toFixed(this.precision);
  }
};
</script>
