<template>
  <KPage>
    <!-- Dados do condomínio -->
    <CondominiumHeader>
      <KChip cyan>
        {{ pagination.total | quantity("coletas", "coleta") }}
      </KChip>
    </CondominiumHeader>

    <v-row class="my-4">
      <v-col cols="12" class="py-1">
        <SectionHeader title="Coletas" />
      </v-col>
    </v-row>

    <!-- Toolbar -->
    <v-toolbar flat class="pl-2">
      <!-- Atualiza -->
      <v-btn icon @click="load">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>

      <v-col>
        <v-text-field
          solo
          flat
          prepend-inner-icon="mdi-magnify"
          class="mr-1"
          clearable
          @keyup.enter.stop.prevent="load(1)"
          @click:clear="onClear"
          hide-details
          v-model="query.q"
          placeholder="Pesquisar pela unidade"
        />
      </v-col>

      <v-col>
        <v-select
          label="Status"
          class="mr-1"
          prepend-inner-icon="mdi-filter-variant"
          :items="statuses"
          v-model="query.status"
          solo
          flat
          clearable
          @change="load"
          hide-details
        />
      </v-col>
    </v-toolbar>
    <!-- {{ payload }} -->

    <!-- Lista -->
    <CollectionsList :items="items" />

    <!--  -->
    <PaginationBar :pagination="pagination" v-model="page" @input="load" />
  </KPage>
</template>

<script>
//
import KPage from "@/components/KPage";
import KChip from "@/components/KChip";
import SectionHeader from "@/components/SectionHeader";
import PaginationBar from "@/components/Pagination/Bar";

//
import CollectionsList from "@/views/Collect/collections/CollectionsList";
import CondominiumHeader from "@/views/Collect/condominiums/CondominiumHeader";
export default {
  components: {
    KPage,
    SectionHeader,
    PaginationBar,
    KChip,
    //
    CollectionsList,
    CondominiumHeader
  },

  data() {
    return {
      pagination: { page: 1 },
      page: 1,
      items: [],
      query: {}
    };
  },

  computed: {
    payload() {
      return {
        condominium: this.$route.params.condominium_id,
        page: this.page || 1,
        ...this.query
      };
    },
    statuses() {
      return this.$store.getters["collections/statuses"];
    }
  },

  methods: {
    async load() {
      try {
        this.$loading.show();
        const {
          data: { data }
        } = await this.$api.get("/collections", this.payload);

        const { data: items, ...pagination } = data;

        this.items = items;
        this.pagination = pagination;
        this.page = pagination.page;
        this.$loading.hide();
      } catch (error) {
        this.$loading.hide();
      }
    },

    onClear() {
      this.$set(this.query, "q", null);
      this.load();
    }
  },

  mounted() {
    this.load();
  }
};
</script>

<style>
</style>
