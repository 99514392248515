<template>
  <v-list-item @click="$emit('click:item')">
    <v-list-item-avatar>
      <v-icon>mdi-cellphone-dock</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <!-- Nome do apartamento -->
        {{ item.condominium_name }} - {{ item.unit_identification }}
        <!-- id -->
        <KChip>#{{ item.id }}</KChip>
        <!-- ícones -->
        <IconsList v-bind="item" />
      </v-list-item-title>
      <v-list-item-subtitle>
        <ChipsList v-bind="item" />

        <!-- icon de arquivo -->
      </v-list-item-subtitle>
    </v-list-item-content>

    <!-- Ações -->
    <ButtonsList
      :id="item"
      :has-charge="!!item.charge_public_id"
      :has-billet="!!item.charge_has_billet"
      @click:edit="$emit('click:edit')"
      @click:reset="$emit('click:reset')"
      @click:cancel="$emit('click:cancel')"
      @click:billet="$emit('click:billet')"
      @click:billet_generate="$emit('click:billet_generate')"
      :hide-actions="hideActions"
    />
  </v-list-item>
</template>

<script>
import KChip from "@/components/KChip";

import ChipsList from "./_shared/ChipsList";
import IconsList from "./_shared/IconsList";
import ButtonsList from "./_shared/ButtonsList";

export default {
  props: {
    hideActions: Boolean,
    item: { type: Object, default: () => ({}) }
  },

  components: {
    KChip,
    ChipsList,
    IconsList,
    ButtonsList
  }
};
</script>

<style>
</style>
