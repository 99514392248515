<!--
  Componente que exibe um JSON no formato string como uma lista
  de dados. 

  @Hot to use
  <log-list title="Body" :content="log.headers" />

  @Attributes

  content {String} required - default: ''
  - O conteúdo propriamente dito. Este conteúdo será um string em formato JSON
    que será convertida para objeto dentro da função
  
  title {String} required
    - O título do cabeçalho do item, exibido acima deste grupo de informações
  
  description {String} optional - default: ''
  - Uma descrição do grupo

  truncate {Boolean} optional - default: false
  - Define se o texto grande será truncado ou ocupará várias linhas
  - Se truncate for true, exiba um botão no cabeçalho para expandir

  closed {Boolean} optional - default: false
  - Define se o grupo inicia fechado

  closable {Boolean} optional - default: true
  - Define se o grupo pode ou não pode ser fechado

  hide-if-empty {Boolean} optional - default: false
  - Define se o grupo será exibido se estiver vazio ou não

  close-if-empty {Boolean} optional - default: false
  - Define se o grupo ficará fechado caso esteja vazio ou não

-->
<template>
  <div class="log-list" v-if="!isHidden">
    <div class="log-list-header">
      <div class="log-list-header-button">
        <!-- <v-btn icon text @click="isTruncated=!isTruncated"> -->
        <v-icon size="16">{{
          isTruncated ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
        <!-- </v-btn> -->
      </div>
      <!--  -->
      <div class="log-list-header-text">
        <div class="log-list-header-text-title primary--text">{{ title }}</div>
        <div class="log-list-header-text-description" v-if="description">
          {{ description }}
        </div>
      </div>

      <div class="log-list-header-button">
        <v-btn icon text @click="state.isTruncated = !state.isTruncated">
          <v-icon size="16">{{
            isTruncated
              ? "mdi-unfold-more-vertical"
              : "mdi-unfold-less-vertical"
          }}</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- {{list}} -->
    <!-- Se não tiver itens -->
    <div v-if="!hasContent" class="log-list-item log-list-item-empty">
      (Vazio){{ list.length }}
    </div>
    <div v-if="hasContent">
      <!-- Se for objeto ou array -->
      <log-list
        :truncateBigTexts="isTruncated"
        v-if="!isString"
        :content="list"
      />
      <!-- Se for string -->
      <log-value
        :truncateBigTexts="isTruncated"
        v-if="isString"
        :content="content"
      />

      <!-- Divisor -->
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
import { isArray, isObject, isString } from "@/plugins/utils";
import LogValue from "./LogValue";
import LogList from "./LogList";

export default {
  name: "log-group",
  components: {
    LogValue,
    LogList
  },
  props: {
    // Conteúdo em string no formato JSON
    content: { required: true }, //ok
    // Título do grupo
    title: { type: String, required: true }, //ok
    // Descrição do grupo
    description: { type: String, default: "" },
    // Define se o texto grande será truncado ou ocupará várias linhas
    truncate: { type: Boolean, default: false },
    // Define se é um grupo que pode ser fechado e expandido
    closable: { type: Boolean, default: true },
    // Define se o grupo inicia fechado. Somente se closable for true
    closed: { type: Boolean, default: false },
    // Define se deve iniciar fechado caso esteja vazio. Somente se closabe for true
    closeIfEmpty: { type: Boolean, default: false },
    // Define se o grupo de deve ficar oculto caso esteja vazio
    hideIfEmpty: { type: Boolean, default: false },
    //
    type: String
  },

  data: () => ({
    //
    state: {
      isTruncated: null
    }
  }),

  computed: {
    list() {
      try {
        if (this.content) {
          // console.log("content", this.content);
          // console.log('entrou');

          const content = JSON.parse(this.content);
          // console.log("length", Object.keys(content).length);
          return content;
        }
        return {};
      } catch (error) {
        // console.log("errro", this.content);
        return this.content;
      }
    },

    hasContent() {
      if (isString(this.list) && this.list === "") return false;
      else if (isArray(this.list) && this.list.length === 0) return false;
      else if (isObject(this.list) && Object.keys(this.list).length === 0)
        return false;
      else return true;
    },

    isArray() {
      return isArray(this.list);
    },

    isObject() {
      return isObject(this.list);
    },
    isString() {
      return isString(this.list);
    },

    //
    isTruncated() {
      // Se existir dados do truncated, retorne. Se não existir,
      // mantenha o padrão da proprieda
      if (this.state.isTruncated) return this.state.isTruncated;
      return this.truncate;
    },

    isHidden() {
      return false;
    }
  },

  methods: {},
  mounted() {
    // Inicia com o valor da propriedade
    // this.state.isTruncated = this.truncate;
  }
};
</script>

<style scoped>
/*  */
.log-list-header {
  display: flex;
  /* background-color: #eee; */
  padding: 8px;
}
.log-list-header.is-expandible:hover {
  background-color: DDD;
}
/*  */
.log-list-header-text {
  width: calc(100% - 60px);
  display: inline-block;
  align-self: center;
}
/*  */
.log-list-header-text-title {
  font-weight: 600;
  font-size: 16px;
}
/*  */
.log-list-header-text-description {
  font-weight: 300;
}
/*  */
.log-list-header-button {
  width: 24px;
  display: inline-flex;
  justify-content: center;
  align-self: center;
}

/* Wrapper de cada item */
.log-list-item {
  width: 100%;
  max-width: 530px;
  float: left;
  user-select: text;
}

.log-list-item-empty {
  font-style: italic;
  color: gray;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid lightgray;
}

/* Nome da chave dos itens */
.log-list-item-key {
  width: 20%;
  /* max-width: 150px; */
  display: inline-flex;
}
/* Valor dos itens */
.log-list-item-value {
  width: 80%;
  display: inline-flex;
  word-break: break-all;
}
/* Valor dos itens caso tenha seu valor truncado */
.log-list-item-value .truncate {
  word-break: unset;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Variações do elemento fechado */

/* Mudar o ícone para indicar que tá fechado */

/* Esconder o conteúdo */
.log-list-closed .log-list-item {
  visibility: hidden;
}
</style>
