<template>
  <div>
    <v-card v-if="showInfo" outlined elevation="0">
      <!-- label -->
      <v-card-title class="caption font-normal px-4 pb-0 pt-1">
        {{ label }}
      </v-card-title>

      <!-- Nome da pessoa -->
      <v-card-title class="subtitle-2 px-4 pt-0 pb-2">
        {{ info && info.name }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          :to="`/people/view/${model && (model.id || model)}`"
          target="_blank"
          ><v-icon>mdi-open-in-new</v-icon></v-btn
        >
        <v-btn icon @click="onClear"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <!-- Documento -->
      <v-card-subtitle class="pb-1 caption">
        <template v-if="info && info.cpf">
          CPF: {{ info.cpf | mask("###.###.###-##") }}
        </template>
        <template v-if="info && info.cnpj">
          CNPJ: {{ info.cnpj | mask("##.###.###/####-##") }}
        </template>
        <template v-if="!info.cpf && !info.cnpj">-</template>
      </v-card-subtitle>
    </v-card>
    <!--  -->
    <PeopleAutocomplete
      v-else
      v-model="model"
      :label="label"
      hint="Digite para começar a pesquisar"
      persistent-hint
      @select="onSelect"
      :search-params="searchParams"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import PeopleAutocomplete from "@/components/PeopleAutocomplete";

// import { TheMask } from "vue-the-mask";

export default {
  name: "person-info-select",

  components: {
    PeopleAutocomplete
    // TheMask
  },

  props: {
    value: Number,
    label: String,
    person: { type: Object, default: () => ({}) },
    searchParams: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      // Armazena o valor do v-mmodel
      model: null,
      // Armazena as informações da pessoa
      info: null,
      // Define se o card de informações será exibido
      showInfo: false
    };
  },

  watch: {
    value(value) {
      this.model = value;
    }
  },

  methods: {
    onSelect(info) {
      this.$emit("input", info.id);
      this.$emit("select", info);

      // console.log("onSelect", info);
      // console.log(info);
      this.showInfo = true;
      this.info = info;
      this.model = info.id;
    },

    onClear() {
      this.$emit("clear");
      this.$emit("input", null);

      this.showInfo = false;
      this.model = null;
      this.info = {};
    }
  },

  mounted() {
    this.model = this.value;
    this.info = this.person;
    if (this.value) this.showInfo = true;
  }
};
</script>

<style>
</style>
