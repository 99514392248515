<template>
  <v-row class="ma-0">
    <v-col cols="12" class="px-0">
      <v-card outlined :loading="loading" v-if="items.length">
        <v-list class="py-0 bordered">
          <v-list-item v-for="item in list" :key="item.id" :to="item.url">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ item.description }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-wrap">
                <KChip :color="item.module && item.module.color" class="mb-1">
                  {{ item.module && item.module.label }}
                </KChip>

                <!-- Número de especificações -->
                <KChip pink class="mb-1">
                  {{
                    item.__meta__.values_count
                      | quantity(
                        "especificações",
                        "especificação",
                        "Nenhuma especificação"
                      )
                  }}
                </KChip>

                <KChip blue class="mb-1">
                  {{
                    item.__meta__.files_count
                      | quantity("arquivos", "arquivo", "Nenhum arquivo")
                  }}
                </KChip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>

      <!-- Se não existem grupos -->
      <KAlert v-if="items && items.length === 0" info>
        Nenhum grupo cadastrado neste módulo
      </KAlert>
    </v-col>
  </v-row>
</template>

<script>
import KChip from "@/components/KChip";
import KAlert from "@/components/KAlert";
export default {
  name: "groups-list",

  components: {
    KChip,
    KAlert
  },

  props: {
    reference_module: [String],
    reference_module_id: [Number, String]
  },

  data() {
    return {
      items: [],
      loading: false
    };
  },

  computed: {
    modules() {
      return this.$store.getters["groups/modules"];
    },
    list() {
      return this.items.map(item => ({
        ...item,
        module: this.modules.find(mdl => mdl.slug === item.reference_module),
        url: `/grouping/${item.id}/${this.reference_module}/${this.reference_module_id}`
      }));
    }
  },

  methods: {
    /**
     *
     */
    async loadItems() {
      // console.log("carregar os itens do serviço");
      try {
        this.loading = true;
        const { data } = await this.$api.get(`/grouping`, {
          count: "1",
          reference_module: this.reference_module,
          reference_module_id: this.reference_module_id,
          visibility_level: 1
        });

        // console.log(data);
        this.loading = false;
        this.items = data.data;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$message.serverError(error);
      }
    }
  },

  mounted() {
    this.loadItems();
    this.$store.dispatch("groups/all");
  }
};
</script>

