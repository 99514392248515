<!--
  Componente que exibe um JSON no formato string como uma lista
  de dados. 

  @Hot to use
  <log-list :content="log.headers" :truncate-text="true" />

  @Attributes

  content {String} required - default: ''
  - O conteúdo propriamente dito. Este conteúdo será um string em formato JSON
    que será convertida para objeto dentro da função


  truncate-big-texts {Boolean} optional - default: false
  - Define se o texto grande será truncado ou ocupará várias linhas
  - Se truncate for true, exiba um botão no cabeçalho para expandir


-->
<template>
  <div class="log-list-items">
    <!-- Conteúdo -->
    <div class="log-list-item mr-2" v-for="(item, key) in content" :key="key">
      <!-- Chave -->
      <div class="log-list-item-key font-weight-medium">{{key}}</div>

      <!-- Valor -->
      <div class="log-list-item-value">
        <div :class="{truncate: truncateBigTexts}">{{item}}</div>
      </div>

      <!-- Divisor -->
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
import { isArray, isObject, isString } from "@/plugins/utils";
export default {
  name: "log-list",
  props: {
    // Conteúdo em string no formato JSON ou Array
    content: { required: true }, //ok
    // Define se o texto grande será truncado ou ocupará várias linhas
    truncateBigTexts: { type: Boolean, default: false }
  },
  data: () => ({}),
  computed: {},
  methods: {},
  mounted() {}
};
</script>

<style scoped>
/* Item mais externo do componente */
.log-list-items {
  width: 100%;
}

/* Wrapper de cada item */
.log-list-item {
  width: 100%;
  float: left;
  user-select: text;
}

/* Nome da chave dos itens */
.log-list-item-key {
  width: 20%;
  /* max-width: 150px; */
  padding-left: 8px;
  display: inline-flex;
}
/* Valor dos itens */
.log-list-item-value {
  width: 80%;
  display: inline-flex;
  word-break: break-all;
}
/* Valor dos itens caso tenha seu valor truncado */
.log-list-item-value .truncate {
  word-break: unset;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>