<template>
  <KDialog
    :visible="visible"
    :title="title"
    :loading="loading"
    @click:close="close"
  >
    <v-row class="my-3">
      <v-col cols="12" class="py-1">
        <SectionHeader title="Detalhes" />
      </v-col>
      <v-col cols="12" class="py-1">
        <v-chip outlined class="mr-2">
          Módulo de Referência:
          <span class="ml-1 font-weight-bold">{{ reference_module }}</span>
        </v-chip>
        <v-chip outlined class="mr-2">
          ID de Referência:
          <span class="ml-1 font-weight-bold">{{ reference_id }}</span>
        </v-chip>
      </v-col>
    </v-row>

    <SectionHeader title="Histórico" />

    <v-card outlined width="100%" class="mb-8">
      <v-list class="py-0" v-if="logs && logs.length">
        <LogItem
          v-for="(log, key) in logs"
          :log="log"
          :key="key"
          @click:open="openDetails(log)"
          class="list-divider"
        ></LogItem>
      </v-list>
    </v-card>

    <!--  -->
    <DialogDetails ref="dialog_details" />
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
import SectionHeader from "@/components/SectionHeader";

import LogItem from "./Item";
import DialogDetails from "./DialogDetails";

export default {
  components: {
    KForm,
    KAlert,
    KDialog,
    SectionHeader,

    DialogDetails,
    LogItem
  },

  data() {
    return {
      visible: false,
      logs: [],
      title: "Logs Relacionados",
      reference_module: null,
      reference_id: null,
      loading: false
    };
  },

  computed: {},

  methods: {
    /**
     * Abre o dialog de detalhes
     */
    openDetails(data) {
      // Envia o this.search para que ele se mantenha quando os dados forem salvos
      // e tudo for recarregado
      this.$refs["dialog_details"].openDetails(data);
    },
    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Abre o dialog
     */
    open(title, logs = [], reference_module, reference_id) {
      this.visible = true;
      this.title = title;
      this.reference_module = reference_module;
      this.reference_id = reference_id;

      if (logs && logs.length) {
        this.logs = logs;
      } else {
        this.loadLogs();
      }
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.visible = false;
      this.clear();
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.title = null;
      this.logs = [];
    },

    /**
     * Carrega os logs
     */
    async loadLogs() {
      try {
        this.loading = true;
        const { data } = await this.$api.get("/logs", {
          reference_module: this.reference_module,
          reference_id: this.reference_id
        });
        this.loading = false;
        console.log(data.data.data);

        this.logs = data.data.data;
      } catch (error) {
        this.loading = false;
        this.$message.serverError(error);
      }
    }
  }
};
</script>

<style>
.v-list .list-divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.theme--light .list-divider {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme--dark .list-divider {
  border-color: rgba(255, 255, 255, 0.12);
}

.v-list .list-divider:last-child {
  border: 0;
}
</style>
