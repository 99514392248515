<template>
  <v-list-item-action v-if="!hideActions" class="flex-row ma-0 align-center">
    <!-- Botão de Ver boleto da Coleta -->
    <v-tooltip bottom v-if="hasCharge && !hasBillet">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          @click.prevent.stop="$emit('click:billet_generate')"
          v-on="on"
        >
          <v-icon>mdi-barcode-off</v-icon>
        </v-btn>
      </template>
      Boleto não gerado
    </v-tooltip>

    <!-- Botão de Ver boleto da Coleta -->
    <v-tooltip bottom v-if="hasCharge && hasBillet">
      <template v-slot:activator="{ on }">
        <v-btn icon @click.prevent.stop="$emit('click:billet')" v-on="on">
          <v-icon>mdi-barcode</v-icon>
        </v-btn>
      </template>
      Boleto
    </v-tooltip>

    <!-- Botão de Editar Coleta -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon @click.prevent.stop="$emit('click:edit')" v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      Editar Coleta
    </v-tooltip>

    <!-- Botão de Resetar Coleta -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon @click.prevent.stop="$emit('click:reset')" v-on="on">
          <v-icon>mdi-network-off-outline</v-icon>
        </v-btn>
      </template>
      Resetar Coleta
    </v-tooltip>

    <!-- Botão de Cancelar Coleta -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon @click.prevent.stop="$emit('click:cancel')" v-on="on">
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
      </template>
      Cancelar Coleta
    </v-tooltip>
  </v-list-item-action>
</template>

<script>
export default {
  props: {
    hideActions: Boolean,
    hasCharge: Boolean,
    hasBillet: Boolean
  }
};
</script>

<style>
</style>
