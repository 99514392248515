<template>
  <v-select :items="items" :multiple="false" v-bind="$attrs" v-model="model">
    <template v-slot:selection="data">
      <v-list-item v-bind="data.attrs" dense class="flex py-0 pl-0">
        <v-list-item-content class="flex py-0">
          <v-list-item-title v-html="data.item[itemText]"></v-list-item-title>
          <v-list-item-subtitle
            v-html="data.item[itemSubtext]"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content>
          <v-list-item-title
            v-html="data.item[itemText]"
            class="caption font-weight-medium"
          ></v-list-item-title>
          <v-list-item-subtitle
            v-html="data.item[itemSubtext]"
            class="caption"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "k-select",

  props: {
    value: [Number],
    items: [Array],
    itemText: String,
    itemSubtext: String
  },

  data: () => ({
    model: null
  }),

  watch: {
    value(val) {
      this.model = val;
    },
    model(val) {
      this.$emit("input", val);
    }
  },

  mounted() {
    this.model = this.value;
  }
};
</script>

<style>
.k-input-select .v-select__selections input {
  display: none;
}
</style>
